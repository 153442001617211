import pck from '../../../../package.json';

export const environment = {
  production: false,
  ENV_NAME: 'black',
  AUTH0_DOMAIN: 'auth0.tiime.fr',
  AUTH0_CLIENT_ID: 'yzIvgseLZ42FSJViygQmap4F5Z0fWQcN',
  AUTH0_REALM: 'Chronos-dev',
  AUTH0_AUDIENCE: 'https://chronos-dev/',
  AUTH0_WHITE_DOMAINS: ['chronos-review-black.preprod.tiime.tech'],
  API_URL: 'https://chronos-review-black.preprod.tiime.tech',
  SENTRY_DNS: null,
  VERSION: pck.version,
  EXPERT_URL: 'https://expert.chronos-review-black.preprod.tiime.tech'
};
